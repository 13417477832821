import React from "react"
import "../styles/styles.css"
import SEO from "../components/seo"
import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"

const NotFoundPage = () => (
  <div>
    <SEO title="404" />
    <Header/>
    <div className="container-404">
      <h1>404</h1>
      <p>Mohon maaf, halaman tidak ditemukan :(</p>
    </div>
    <Footer/>
  </div>
)

export default NotFoundPage
